<template>
  <div class="content-component" @click="handleClearSelection">
    <el-button
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      >{{ __("Create Intent") }}
    </el-button>
    <el-dropdown
      v-show="can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      split-button
      type="primary"
      @click.stop="createContentItem"
      @command="handleCommand"
    >
      {{ __("Create Intent") }}
      <el-dropdown-menu class="mediumDropdown" slot="dropdown">
        <el-dropdown-item
          command="import"
          :disabled="!can('content.' + additionalValidateRoute + '.write')"
          >{{ __("Import Intent") }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :show-content-view-switcher="true"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              :class="contentViewClasses"
              v-if="intents.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
            >
              <content-list-item
                :content-view-type="contentViewType"
                v-for="(intent, index) in intents"
                :item-index="index"
                :key="'_content_intent_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [intent[primaryKey]]
                }"
                :allow-multi-select="intent.is_archived === 1 ? true : false"
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-open-form-grey.svg"
                :card-text="intent.name"
                :content-list-item="intent"
                :fields-to-display="displayFields"
                @delete="handleDelete(intent, 'Intent', intent.intent_id)"
                @select="handleSelect(index, intent)"
                @edit="handleEdit(index, intent)"
                @checked-content="handleSelectMultiple($event)(intent)"
                @toggle-select-all="
                  handleToggleSelectAll(intents, ...arguments)
                "
                :is-all-selected="isAllSelected"
                :total-items-selected="selectedItems.length"
              >
              </content-list-item>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              {{ __("No Intents") }}
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentListItem from "@/views/build/content/mixins/ContentListItem";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
export default {
  name: "IntentList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentListItem
  },
  selectedItemIndex: {
    required: false,
    default: null
  },
  data() {
    return {
      additionalValidateRoute: "intents",
      sortByColumns: ["name"],
      isContent: true,
      viewMode: "allow_read",
      displayFields: [
        {
          field_name: "name",
          display_name: __("Name")
        },
        {
          field_name: "description",
          display_name: __("Description")
        },
        {
          field_name: "open_form_type",
          display_name: __("Open Formtype")
        }
      ]
    };
  },

  computed: {
    ...mapState("intents", {
      intents: state => state.intents,
      loading: state => state.loading
    }),
    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    }
  },

  methods: {
    ...mapActions("intents", {
      contentAPI: "getIntents"
    }),
    handleCommand() {
      if (this.canAddEditOrDelete()) {
        this.handleImport();
      } else {
        this.showPermissionError();
      }
    }
  }
};
</script>
<style lang="scss">
.content-openformtype-list {
  .nlp_engine {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
$content-theme-color: #4db3f6 !default;
$content-theme-hover-color: dodgerblue !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";

::v-deep .el-select {
  input {
    height: 44px;
    border-color: #f5f5f8;
    color: #a0a8b5;
  }

  width: 90px;
  .el-input.is-focus .el-input__inner {
    border-color: var(--theme-color) !important;
  }

  .el-input__inner:hover {
    border-color: var(--theme-color) !important;
  }

  .el-select-dropdown__item.selected {
    color: var(--theme-color) !important;
  }

  .el-input__inner:focus {
    border-color: var(--theme-color) !important;
  }
}
</style>
